import * as React from "react"
import { useEffect, useState } from "react"
import dataJSON from '../data/data.js'
import Init from '../common/init'
import './style.css'
//import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
//import { Navigation } from "swiper";
import "swiper/css/navigation";
import Page404 from './404'

import Layout from '../layouts/default/layout';

import SectionComponent from '../components/section_component'

const isBrowser = typeof window !== "undefined"


const IndexPage = (props) => {

  const [appPages] = useState(dataJSON.app_pages)
  const [SectionsForPage] = useState(returnSectionForPage(props.params.name))



  const returnPages = React.useCallback(() => { 
    let result = []
    Object.keys(appPages).map(item=>{
      result.push(appPages[item].page_path)
      return true
    })
    return result;
  }, [appPages]);
  

 function returnSectionForPage(page = ''){
    let result = []
    Object.keys(dataJSON.app_sections).map(item=>{
     dataJSON.app_sections[item].app_pages.map(item2=>{
       if(item2.primaryDisplay === '/'+page) result.push(dataJSON.app_sections[item])
       return true
     })
     return true
    })

    return result;
  }



  const [data, setData] = useState({})
  const [pageReady, setPageReady] = useState(false)

  useEffect(()=>{
    setData(dataJSON)    
    if(returnPages().filter(item=>item === '/'+props.params.name).length){
      dataJSON.app_pages.map(item=>{
        if(item.page_path==='/'+props.params.name){
          if(item.redirect_to){
            if (!isBrowser) return true;
            window.location.href = item.redirect_to
          }
        }
        return true
      })
      
    }
  }, [props, returnPages])

  
  return (
    <>
      <Init setPageReady={setPageReady} />
      <Layout data={data} style={{display:pageReady?'':'none'}} >
        <div className="">
          {
            (()=>{
              if(returnPages().filter(item=>item === '/'+props.params.name).length){
                return(
                  <div className="flex flex-col ">

                    {
                      SectionsForPage.map((data, key)=>{
                        return(
                          <SectionComponent key={key} data={data}/>
                        )
                      })
                    }
                  </div>
                )
              }else{
                return(
                  <div className="row">
                    <Page404></Page404>
                  </div>
                )
              }
              
            })()
          }
          
        </div>
      </Layout>

    </>
  )
}

export default IndexPage
